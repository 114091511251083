<template>
  <b-row>
    <b-col md="4">
      <b-card
        :img-src="require(`@/assets/images/promotions/rizz888/3.png`)"
        img-alt="Promotion"
        img-top
        title="ฝากครั้งแรกของวัน รับสูงสุด 500 บาท"
      >
        <b-card-text>
          <v-select
            v-model="selected"
            label="title"
            :options="firsts"
            :clearable="false"
            @input="onPromotionChange"
          />
        </b-card-text>
        <b-card-text>
          <b-alert
            ref="alert-message"
            :show="selected.title != 'ไม่รับ'"
            variant="warning"
          >
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              เงืื่อนไข:
              <ul>
                <li>ฝากเงินขั้นต่ำ {{ selected.minimum_deposit }} บาท</li>
                <li>
                  รับโบนัส {{ selected.value }}% สูงสุด
                  {{ selected.maximum }} บาท
                </li>
                <li>
                  โบนัสสามารถถอนออกได้หลังจากเล่นครบ
                  {{ selected.turnover }} เทิร์น
                </li>
              </ul>
            </div>
          </b-alert>
        </b-card-text>
        <b-alert ref="alert-message" show variant="success">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            เมื่อตั้งค่ารับโปรโมชั่นแล้ว
            โบนัสจะเข้าอัตโนมัติหลังจากฝากเงินครั้งแรกของวัน
          </div>
        </b-alert>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card
        :img-src="require(`@/assets/images/promotions/rizz888/6.png`)"
        img-alt="Promotion"
        img-top
        title="โปรโมชั่นนาทีทอง 20:00-21:00"
      >
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card
        :img-src="require(`@/assets/images/promotions/rizz888/2.png`)"
        img-alt="Promotion"
        img-top
        title="กิจกรรมประจำวันลุ้นรับเครดิตฟรี เพียงฝากเงิน 100 บาท"
      >
      </b-card>
    </b-col>
    <!-- <b-col md="4">
      <b-card
        :img-src="require(`@/assets/images/promotions/ads10_1.jpg`)"
        img-alt="Promotion"
        img-top
        title="โปรแนะนำเพื่อนรับ 2 ต่อหารายรับได้เป็นแสนต่อเดือน"
      >
        <b-card-text>
          เงื่อนไข:
          <ul>
            <li>ต่อแรกรับไปเลย 50% ของยอดฝากเพื่อน สูงสุด 200 บาท</li>
            <li>ต่อที่สองรับเงิน 5% ของยอดเสียเพื่อน</li>
          </ul>
        </b-card-text>
      </b-card>
    </b-col> -->
    <!-- <b-col md="4">
      <b-card
        :img-src="require(`@/assets/images/promotions/comission.png`)"
        img-alt="Promotion"
        img-top
        title="รับโบนัสเงินคืนด้วยตัวเองได้ทุกวัน"
      >
        <b-card-text>
          เงื่อนไข:
          <ul>
            <li>รับโบนัสจากยอดสะสมการเล่น คาสิโน และ สล็อต 0.3-0.8%</li>
            <li>ยอดโบนัสสามารถกดรับได้ทุกวันหลัง 07.00น</li>
            <li>
              โบนัสสามารถถอนออกได้หลังจากเล่นครบ 3 เทิร์น
            </li>
          </ul>
        </b-card-text>
      </b-card>
    </b-col> -->
    <b-col md="4">
      <b-card
        :img-src="require(`@/assets/images/promotions/rizz888/4.png`)"
        img-alt="Promotion"
        img-top
        title="คืนยอดเสีย 5% กดรับได้ทุกวัน หลัง 00:15น"
      >
        <b-card-text>
          เงื่อนไข:
          <ul>
            <li>ระบบจะคืนยอดเสียอัตโนมัติ 5% ทุกวัน หลัง 00:15น</li>
            <li>ยอดเสียคืนสูงสุดต่อวัน 300 บาท</li>
            <li>
              โบนัสสามารถถอนออกได้หลังจากเล่นครบ 1 เทิร์น
            </li>
          </ul>
        </b-card-text>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card
        :img-src="require(`@/assets/images/promotions/rizz888/7.png`)"
        img-alt="Promotion"
        img-top
        title="ติดตามเร็วๆนี้.."
      >
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BAlert } from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BCard,
    BCardText,
    BAlert
  },
  data() {
    return {
      selected: {
        title: 'ไม่รับ',
        turnover: 0,
        minimum_deposit: 100,
        maximum: 100,
        value: 10
      },
      golden: { title: 'ไม่รับ' },
      firsts: [{ title: 'ไม่รับ' }],
      goldenHourOptions: [{ title: 'ไม่รับ' }, { title: 'รับ', id: 7 }]
    }
  },
  methods: {
    async getFirstInvestPromotion() {
      const promotions = await this.$http.get('member/promotions/first')
      promotions.data.forEach(promo => {
        this.firsts.push(promo)
      })
    },
    async getGoldenHourPromotion() {
      await this.$http.get('member/promotions/golden')
    },
    async getFirstInvestPromotionSetting() {
      const promotions = await this.$http.get(
        'member/setting/promotion/first_invest'
      )
      if (promotions.data.promotion) {
        if (promotions.data.promotion.is_active === 1) {
          this.selected = {
            title: promotions.data.promotion.title,
            value: promotions.data.promotion.value,
            turnover: promotions.data.promotion.turnover,
            minimum_deposit: promotions.data.promotion.minimum_deposit,
            maximum: promotions.data.promotion.maximum,
            id: promotions.data.promotion.id
          }
        } else {
          this.selected = { title: 'ไม่รับ' }
        }
      }
    },
    async getGoldenHourPromotionSetting() {
      const golden = await this.$http.get(
        'member/setting/promotion/golden_hour'
      )

      if (golden.data.promotion) {
        if (golden.data.promotion.is_active === 1) {
          this.golden = {
            title: 'รับ',
            id: golden.data.promotion.id
          }
        } else {
          this.golden = { title: 'ไม่รับ' }
        }
      }
    },
    onPromotionChange() {
      console.log('xxx')
      this.$nextTick(() => {
        this.showMsgBoxTwo('first_invest')
      })
    },
    onGoldenHourChange() {
      console.log('xxx')
      this.$nextTick(() => {
        this.showMsgBoxTwo('golden_hour')
      })
    },
    async configPromotion(type) {
      // console.log(type)
      let promoId
      let isActive
      if (type === 'first_invest') {
        promoId = this.selected.id
        if (this.selected.title === 'ไม่รับ') {
          isActive = false
        } else {
          isActive = true
        }
      } else {
        promoId = this.golden.id
        if (this.golden.title === 'ไม่รับ') {
          isActive = false
        } else {
          isActive = true
        }
      }
      console.log(promoId)
      const resp = await this.$http.post('member/config/promotion', {
        type,
        promotion_id: promoId,
        is_active: isActive
      })
      if (resp.data.success === true) {
        console.log('fff')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ปรับการตั้งค่าโปรโมชั่นเรียบร้อยแล้ว',
            icon: 'EditIcon',
            variant: 'success'
          }
        })
      }

      console.log(resp)
    },
    showMsgBoxTwo(type) {
      this.$bvModal
        .msgBoxConfirm(`กรุณาทำการศึกษาข้อมูลเงื่อนไขต่างๆให้ครบถ้วน`, {
          title: 'ยืนยันการรับโปรโมชั่น',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'ยืนยัน',
          cancelTitle: 'ยกเลิก',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          console.log(value)
          if (value === true) {
            this.configPromotion(type)
          }
        })
    }
  },
  async beforeMount() {
    await this.getFirstInvestPromotionSetting()
    await this.getFirstInvestPromotion()
    await this.getGoldenHourPromotionSetting()
    await this.getGoldenHourPromotion()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
